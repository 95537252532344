// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
[data-v-95ca6978]  .v-color-picker {
  box-shadow: initial;
}
[data-v-95ca6978]  .popper {
  background-color: #ffffff;
  padding-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./websrc/components/config/ColorPicker.vue"],"names":[],"mappings":";AAkDA;EACE,mBAAmB;AACrB;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":["<template>\n  <popper\n    v-if=\"visible\"\n    trigger=\"clickToOpen\"\n    force-show\n    :boundaries-selector=\"reference\"\n    :options=\"{\n      placement: 'auto',\n    }\">\n    <div class=\"popper\">\n      <v-color-picker class=\"ma-2\" hide-inputs :color.sync=\"color\" />\n      <v-btn color=\"primary\" @click=\"hide(true)\">{{ $t('generics.ok') }}</v-btn>\n      <v-btn color=\"primary\" @click=\"hide(false)\">{{ $t('generics.cancel') }}</v-btn>\n    </div>\n\n    <div slot=\"reference\"></div>\n  </popper>\n</template>\n\n<script>\nimport Vue from \"@vue/compat\";\nimport Popper from 'vue-popperjs'\n\nexport default {\n  components: { Popper },\n  data() {\n    return {\n      color: null,\n      reference: null,\n      callback: null,\n      visible: false,\n    }\n  },\n  methods: {\n    hide(save) {\n      if (save) this.callback(this.color)\n      this.reference = null\n      this.callback = null\n      this.visible = false\n    },\n    showPicker(reference, callback) {\n      this.reference = reference\n      this.callback = callback\n      this.$nextTick(() => this.visible = true)\n    }\n  }\n}\n</script>\n\n<style scoped>\n:deep() .v-color-picker {\n  box-shadow: initial;\n}\n\n:deep() .popper {\n  background-color: #ffffff;\n  padding-bottom: 8px;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
