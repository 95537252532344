<template>
  <div :class="!showMeetingLine ? 'hideTimeline' : 'contentGridWrapper'" class="py-4">
    <div v-if="loading" class="loadingContent d-flex align-center mt-1">
      <v-btn
        icon
        variant="text"
        loading
      ></v-btn>
      <span>{{ $t('generics.loading') }}</span>
    </div>
    <div :class="loading ? 'filteringContentWithLoading' : 'filteringContent'" class="d-flex align-center mt-1" v-if="filteringBy">
      <div class="mr-4" v-for="number in numberOfTimesFound" :key="number">
        <v-btn :color="filterPosition === (number-1) ? 'red':''" icon density="compact" variant="outlined" x-small @click="filterElements(filteringBy, number-1)">{{ number }}</v-btn>
      </div>
      <div class="mr-4">
        <v-btn :color="filterPosition === 'all' ? 'red':''" icon density="compact" variant="outlined" x-small @click="filterElements(filteringBy, 'all')"><span style="font-size: 10px;">all</span></v-btn>
      </div>
      <div class="mr-4">
        <v-btn density="compact" icon variant="outlined" x-small @click="cleanOrganigramFilter();"><font-awesome-icon
          :icon="['fal', 'times']"
          :style="{ fontSize: '15px', color: isDark ? 'white': '' }"
        /></v-btn>
      </div>
      <div>
        <span class="text-caption font-weight-bold mr-2">{{getNameByUuid(filteringBy)}} {{ $t("generics.organisation")}} </span>
      </div>
    </div>
    <OrganisationCard
      class="px-4 pt-4"
      :loadingGrid="loading"
      :showSectionFunction="showSectionFunction"
      :isChildren="false"
      :items="dataOrganigram || []"
      :editMode="editMode"
      :openAddEditModal="openAddEditModal"
      :filteringBy = "filteringBy"
    />
    <DeleteAreaModal
      v-if="showDeleteAreaModal"
      key="deleteDialog"
      :closeModal="() => {this.showDeleteAreaModal = false}"
      :deleteFunction="removeOrganigramArea"
      :showModal="showDeleteAreaModal"
    />
  </div>
</template>

<script>
import store, { EventBus } from "../../../store";
import { aDelay } from '../../../lib/asyncUtil';
import { setNamespaceSetting } from '../../../lib/wsMsg';
import { canIShowOrganigram, getPersonsSortByColor } from '../../../utils/basicFunctions';
import OrganisationCard from "./organisationCards.vue";
import { setAddEditOrganigramModalEvent, resetAddEditOrganigramModalEvent } from "../../../effector/modals";
import { basicView, isWaitingRoomUser,} from "../../../utils/privileges";
import DeleteAreaModal from "../../modal/deleteAreaModal.vue";

export default {
  directives: {},
  components: {
    OrganisationCard,
    DeleteAreaModal
  },
  data() {
    return {
      state: store.state,
      dataOrganigram: [],
      editMode: false,
      loading: true,
      filteringData: undefined,
      filteringBy: null,
      oldOrganigram: JSON.stringify(store.state.namespaceSettings.newOrganigram || []),
      newOrganigram: store.state.namespaceSettings.newOrganigram || [],
      setCurrentContentVisile: store.setCurrentContentVisile,
      numberOfTimesFound: 0,
      filterPosition: 0,
      name: "",
      showDeleteAreaModal: false,
      editOrganigramData: undefined,
    };
  },
  watch: {
    newOrganigram: {
      deep: true,
      handler: function (newVal) {
        const oldVal = this.oldOrganigram;
        if (!this.amIAdmin || !newVal || JSON.stringify(newVal) === oldVal) return;
        if (this.loading) return console.error('Tried to save organigram while loading!');
        setNamespaceSetting("newOrganigram", newVal);
        this.oldOrganigram = JSON.stringify(newVal);
      },
    },
    'state.user.organisationEditable': {
      deep: true,
      immediate: true,
      handler: function (organisationEditable) {
        // if(organisationEditable){
           this.setEditMode(organisationEditable);
        // }
      },
    },
    '$route': {
      deep: true,
      immediate: true,
      handler: function (to, from) {
        this.filteringBy = null;
        const newUrl = to.path;
        const segments = newUrl.split('/').filter(item => item !== ""); // Split the URL into parts
        if (segments && segments[0] === 'organisation' && segments[1]) {
          const uuid = segments.pop(); // Get the last part which is assumed to be the UUID
          if (uuid) {
            this.dataOrganigram = [];
            this.filteringBy = uuid;
            this.setEditMode(false);
            store.setOrganisationEditableView(false);
            this.$nextTick(() => {
              this.filterOrganigramByUser(uuid, 0);
            });
          }
        }
        if (!this.filteringBy) {
          this.filterOrganigramByUser(null);
        }
      }
    },
  },
  created() {
    if (!this.showOrganigram) {
      return this.gotoPage("home");
    }
  },
  mounted() {
    EventBus.$on("saveOrganigram", this.saveOrganigram);
    EventBus.$on("removeSectionOrganigram", this.removeSectionOrganigram);
    EventBus.$on("deleteConfirmOrganigramModalEvent", this.showDeleteConfirmationOrganigram);
  },
  unmounted() {
    EventBus.$off("saveOrganigram", this.saveOrganigram);
    EventBus.$off("removeSectionOrganigram", this.removeSectionOrganigram);
    EventBus.$off("deleteConfirmOrganigramModalEvent", this.showDeleteConfirmationOrganigram);
  },
  methods: {
    removeOrganigramArea(){
      const data = this.editOrganigramData;
      EventBus.$emit("removeSectionOrganigram", data);
      this.showDeleteAreaModal = false;
      this.closeModal();
    },
    closeModal() {
      resetAddEditOrganigramModalEvent();
    },
    loadInitialData(data) {
      // Load only the main information, not the 'children' property
      return data.map(node => {
        const { children: _, ...rest } = node; // discard the 'children' property
        return rest;
      });
    },
    // Function to sleep/wait for a defined time in ms.
    sleep(ms) {
      return aDelay(ms);
    },
    /*
    async graduallyAddChildren(data, originalData, interval) {
      for (let i = 0; i < originalData.length; i++) {
        // Assuming that the id of originalData corresponds to the node in data
        const nodeId = originalData[i].id;
        const nodeIndex = data.findIndex(node => node.id === nodeId);
        if (nodeIndex !== -1) {
          // Set the children after waiting the specified interval
          await this.sleep(interval);
          data[nodeIndex].children = originalData[i].children;
        }
      }
      return data;
    },
    */
    async graduallyAddFirstChildren(data, originalData, interval) {
      for (let i = 0; i < originalData.length; i++) {
        // Assuming that the id of originalData corresponds to the node in data
        const nodeId = originalData[i].id;
        const nodeIndex = data.findIndex(node => node.id === nodeId);
        if (nodeIndex !== -1 && originalData[i].children && originalData[i].children.length > 0) {
          // Set the first child after waiting the specified interval
          await this.sleep(interval);
          data[nodeIndex].children = [originalData[i].children[0]];
        }
      }
      return data;
    },
    filterElements(filteringBy, position){
      this.filterPosition = position;
      if ((position || position === 0) && position !== 'all') {
        EventBus.$emit("expandUsers");
      }
      this.filterOrganigramByUser(filteringBy, position);
    },
    saveOrganigram(data) {
      const dataToSave = data[0];
      const positionData = data[1];
      if (positionData.edit) {
        this.editElementAtSameLevel(
          positionData.data.id,
          dataToSave,
          this.newOrganigram,
          positionData.isOpen
        );
        this.$nextTick(() => {
          this.showSectionFunction(positionData.data.id, positionData.isOpen);
        });
      } else {
        if (!this.newOrganigram.length) {
          this.newOrganigram.push(dataToSave);
          this.prepareDataToOrganigram(this.newOrganigram);
        } else {
          this.insertElementAtSameLevel(
            positionData.data.id,
            dataToSave,
            this.newOrganigram,
            positionData.isOpen
          );
          this.newOrganigram = JSON.parse(JSON.stringify(this.newOrganigram));
          this.$nextTick(() => {
            this.showSectionFunction(positionData.data.id, positionData.isOpen);
          });
        }
      }
    },
    showDeleteConfirmationOrganigram(data){
      this.showDeleteAreaModal = true;
    },
    removeSectionOrganigram(data) {
      const id = data.id;
      this.removeElementById(this.newOrganigram, id);
      try {
        this.removeElementById(this.dataOrganigram, id);
      } catch (err) {
        console.error('An error occurred:', err);
        this.dataOrganigram = [];
        this.$nextTick(() => {
          this.prepareDataToOrganigram(this.newOrganigram);
        });
      }
    },
    cleanOrganigramFilter() {
      this.filterPosition = 0;
      this.$router.push({ path: '/organisation' });
      this.filterOrganigramByUser();
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getPersonByUuid(uuid) {
      return store.getPersonByUuid(uuid);
    },
    safeLocaleCompare(a, b) {
      if (a && b) {
        return a.localeCompare(b, 'de', { sensitivity: 'base' });
      } else if (a) {
        return -1; // 'a' comes first
      } else if (b) {
        return 1; // 'b' comes first
      } else {
        return 0; // considered equal
      }
    },
    sortOrganigramByName(organigram, sortByDepartment = true) {
      organigram.sort((a, b) => {
        let nameComparison = 0; // By default we skip to Compare by department name
        // First compare by section/department name
        if (sortByDepartment) {
          nameComparison = this.safeLocaleCompare(a.name, b.name);
        }
        // If section/position names are the same, compare by supervisors[0]
        if (nameComparison === 0 && a.supervisors && a.supervisors[0] && b.supervisors && b.supervisors[0]) {
          // Try to order by Supervisor[0] (First User) name, surname or statusColor as selected at userSettings
          const orderBy = store.state.user.userSettings.organigramUsersSort;
          const userA = this.getPersonByUuid(a.supervisors[0]).user;
          const userB = this.getPersonByUuid(b.supervisors[0]).user;
          if (orderBy == 'alphabetical') {
            return this.safeLocaleCompare((userA.firstName ? userA.firstName : userA.name), (userB.firstName ? userB.firstName : userB.name));
          } else if (orderBy == 'lastName') {
            return this.safeLocaleCompare((userA.lastName ? userA.lastName : userA.name), (userB.lastName ? userB.lastName : userB.name));
          } else if (orderBy == 'statusColor') {
            // Check colorComparison, if they're the same color, we sort it by lastName or name
            const colorComparison = getPersonsSortByColor(this.getPersonByUuid(a.supervisors[0]), this.getPersonByUuid(b.supervisors[0]));
            const colorComparisonResult = colorComparison ? colorComparison : this.safeLocaleCompare((userA.lastName ? userA.lastName : userA.name), (userB.lastName ? userB.lastName : userB.name));
            return colorComparisonResult;
          }
        }
        // Otherwise, use the result of name comparison
        return nameComparison;
      });
      organigram.forEach((item) => {
        if (item.children && Array.isArray(item.children)) {
          this.sortOrganigramByName(item.children, item.sortByDepartment); // Recursively sort any children
        }
      });
    },
    gotoPage(route) {
        this.setCurrentContentVisile(route, true, this.$router);
      },
    filterOrganigramByUser(uuid, position = null) {
      this.loading = true;
      if (!uuid) {
        this.filteringData = undefined;
        this.dataOrganigram = [];
        this.$nextTick(() => {
          // Load initial data without children properties
          const loadedOrganigram = this.loadInitialData(this.newOrganigram);
          // Prepare the initially loaded data for the organigram
          this.prepareDataToOrganigram(loadedOrganigram);
          // Gradually add children to the nodes of the organigram at set intervals
          // this.graduallyAddChildren(loadedOrganigram, this.newOrganigram, 300) // Old
          this.graduallyAddFirstChildren(loadedOrganigram, this.newOrganigram, 200)
            .then(data => {
              console.debug('All children have been added');
              // Add data to the organigram view
              this.prepareDataToOrganigram(data);
              this.loading = false;
            }, error => console.error('An error occurred:', error));
        });
      } else {
        const dataCopy = JSON.parse(JSON.stringify(this.newOrganigram));
        const dataResult = this.checkUuidPresence(dataCopy, uuid.toString());
        this.numberOfTimesFound = dataResult.length;
        const showingPosition = position || 0;
        if (position === 'all' || !dataResult.length) {
          this.dataOrganigram = [];
          this.filteringData = dataResult;
          this.$nextTick(() => {
            this.dataOrganigram = dataResult;
            this.loading = false;
          });
        } else {
          this.dataOrganigram = [dataResult[showingPosition]];
          this.filteringData = [dataResult[showingPosition]];
          this.loading = false;
        }
      }
    },
    prepareDataToOrganigram(data) {
      this.dataOrganigram = data;
      this.sortOrganigramByName(this.dataOrganigram);
    },
    showSectionFunction(id, isOpen) {
      let dataCopy = JSON.parse(JSON.stringify(this.newOrganigram));
      if (this.filteringData) {
        dataCopy = JSON.parse(JSON.stringify(this.filteringData));
      }
      // this.dataOrganigram = this.filterArrayById(dataCopy, id, isOpen);
      // this.sortOrganigramByName(this.dataOrganigram); // Old
      const filteredData = this.filterArrayById(dataCopy, id, isOpen);
      if (filteredData?.length>1) {
        this.loading = true
        // Load initial data without children properties
        const loadedOrganigram = this.loadInitialData(this.newOrganigram);
        // Prepare the initially loaded data for the organigram
        this.prepareDataToOrganigram(loadedOrganigram);
        // Gradually add children to the nodes of the organigram at set intervals
        this.graduallyAddFirstChildren(filteredData, this.newOrganigram, 200)
          .then(data => {
            console.debug('All children have been added');
            // Add data to the organigram view
            this.prepareDataToOrganigram(data);
            this.loading = false;
          }, error => console.error('An error occurred:', error));
      } else if (filteredData) {
        this.prepareDataToOrganigram(filteredData);
      } else {
        console.warn('Filtered data is empty:', filteredData);
      }
    },
    filterArrayById(data, id, isOpen) {
      // Iterate through the data array
      for (let i = 0; i < data.length; i++) {
        // Check if the current object's id matches the provided id
        if (data[i].id === id) {
          if (!isOpen) {
            // if not open return the actual data
            return [...data];
          }
          return [data[i]]; // Return the object as an array if the id matches
        }
        // If the current object has children, recursively search through them
        if (data[i].children && data[i].children.length) {
          const result = this.filterArrayById(data[i].children, id, isOpen);
          if (result) {
            data[i].children = result;
            return [data[i]]; //modify children object and return
          }
        }
      }
      // Return null if the id is not found in the data
      return null;
    },
    insertElementAtSameLevel(id, newElement, data, isOpen) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          if (!isOpen && newElement.id !== id) {
            data.splice(i + 1, 0, newElement);
          } else {
            if (newElement.children && newElement.children.length) {
              data[i].children = [];
              newElement["children"].forEach((element) => {
                data[i].children.push(element);
              });

            } else {
              if (data[i].children && data[i].children.length) {
                data[i].children.push(newElement);
              } else {
                data[i]["children"] = [];
                data[i].children.push(newElement);
                // data.splice(i + 1, 0, newElement);
              }
            }
          }
          break;
        } else if (data[i].children) {
          this.insertElementAtSameLevel(
            id,
            newElement,
            data[i].children,
            isOpen
          );
        }
      }
    },
    editElementAtSameLevel(id, newElement, data, isOpen) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          data[i].name = newElement.name;
          data[i]["assistants"] = newElement.assistants || [];
          if (data[i].supervisors) {
            data[i].supervisors = newElement.supervisors || [];
          }
          if (newElement.sortByDepartment !== undefined) {
            data[i].sortByDepartment = newElement.sortByDepartment;
          }
          if (newElement.children) {
            data[i].children = [];
            newElement["children"].forEach((element) => {
              data[i].children.push(element);
            });
          }
          return true; // exit the function when the desired element is found
        } else if (data[i].children) {
          const result = this.editElementAtSameLevel(
            id,
            newElement,
            data[i].children,
            isOpen
          );
          if (result) {
            break;
          }
        }
      }
    },
    removeElementById(data, id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          data.splice(i, 1);
          return;
        }
        if (data[i].children) {
          this.removeElementById(data[i].children, id);
        }
      }
    },
    getElementParentUsers(id, data) {
      let returnData = null;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          returnData = data[i];
          break; // exit the loop when the desired element is found
        } else if (data[i].children && data[i].children.length && !returnData) {
          returnData = this.getElementParentUsers(id, data[i].children);
          if (returnData) {
            break; // exit the loop if the desired element is found in the recursive call
          }
        }
      }
      return returnData;
    },
    setEditMode(value) {
      if (value && this.filteringBy) {
        this.editMode = false;
      }
      this.editMode = value;
    },
    openAddEditModal(data, edit, isOpen) {
      let dataToSend = data;
      if (data && data.isUser) {
        const dataCopy = JSON.parse(JSON.stringify(this.newOrganigram));
        dataToSend = this.getElementParentUsers(data.isUser, dataCopy);
        dataToSend['usersSections'] = true;
      }
      // Local control variable
      this.editOrganigramData = dataToSend;
      // Open modal
      setAddEditOrganigramModalEvent({
        show: true,
        data: dataToSend,
        edit: edit,
        isOpen: data && data.isUser ? true : isOpen,
      });
    },
    checkUuidPresence(data, uuid) {
      const traverseArray = (arr, level = 0) => {
        let parentCount = {};
        return arr.reduce((result, obj, index, arr) => {
          // Gather siblings excluding the current object.
          const siblings = level < 3 ? [] : arr.filter((_, idx) => idx !== index);
          const siblingsWithoutChildren = siblings.map(({ children, ...rest }) => rest);
          // Verificar si hay más objetos con el mismo UUID en los hijos.
          if (obj.children && obj.children.length > 0) {
            const filteredChildren = traverseArray(obj.children || [], level + 1);
            // Si hay varios hijos con el UUID, duplica el padre y distribuye a los hijos.
            if (filteredChildren.length > 0) {
              // Inicializar contador para este padre si aún no ha sido creado.
              if (!parentCount[obj.uuid]) {
                parentCount[obj.uuid] = 0;
              }
              // Duplicar el padre solo si hay más de un hijo con el uuid.
              if (filteredChildren.length > 1) {
                filteredChildren.forEach(child => {
                  // Contar cuántas veces hemos visto este padre.
                  parentCount[obj.uuid]++;
                  // Add siblings before pushing to result
                  if (parentCount[obj.uuid] === 1) {
                    result.push({ ...obj, siblings: siblingsWithoutChildren, children: [child] });
                  } else {
                    result.push({
                      ...obj,
                      siblings: siblingsWithoutChildren,
                      id: obj.id + '_' + parentCount[obj.uuid],
                      children: [child]
                    });
                  }
                });
              } else {
                result.push({ ...obj, siblings: siblingsWithoutChildren, children: filteredChildren });
              }
              return result;
            }
          }
          // Obtener asistentes basado en los UUIDs de supervisores y asistentes.
          const assistants = this.getPersonAssistantsByUuids(obj.supervisors || [], obj.assistants || []);
          // Añadir a result si el UUID esta presente en assistants.
          if (assistants.includes(uuid)) {
            result.push({ ...obj, siblings: siblingsWithoutChildren });
            return result;
          }
          // Revisar si el UUID coincide en el nivel actual.
          if ((obj.uuid && obj.uuid === uuid) ||
              (obj.supervisors && obj.supervisors.includes(uuid))) {
            // Agregar el objeto si coincide con el UUID y aún no se ha añadido al resultado.
            if (!parentCount[obj.uuid]) {
              result.push({ ...obj, siblings: siblingsWithoutChildren });
            }
          }
          return result;
        }, []);
      };
      const dataResult = traverseArray(data || []);
      return dataResult;
    },
    getPersonAssistantsByUuids(uuids, assistantsData) {
      let uniqueAssistants = new Set(assistantsData || []);
      if (uuids && uuids.length) {
        for (let i = 0; i < uuids.length; i++) {
          const uuid = uuids[i];
          const person = store.state.group[uuid]?.user;
          if (person && Array.isArray(person.assistants)) {
            for (let j = 0; j < person.assistants.length; j++) {
              const assistant = person.assistants[j];
              uniqueAssistants.add(assistant);
            }
          }

        }
      }
      return Array.from(uniqueAssistants);
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    showMeetingLine() {
      return (
        !this.isWaitingRoomUser &&
        this.state.user.userSettings.showVideoCallPlanner &&
        !this.isBasicView
      );
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.state.ownUUID);
    },
    isBasicView() {
      return basicView();
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
  },
};
</script>

<style scoped lang="scss">
.filteringContent{
  position: absolute;
  top: 2px;
  right: 5px;
  z-index: 1;
}
.filteringContentWithLoading{
  position: absolute;
  top: 2px;
  right: 130px;
  z-index: 1;
}
.loadingContent {
  position: absolute;
  top: 2px;
  right: 5px;
  z-index: 1;
}
.contentGridWrapper {
  height: calc(100vh - 130px);
  overflow: auto;
  overflow-x: hidden;
}
.hideTimeline{
    height: calc(100vh - 70px)!important;
    overflow: auto;
    overflow-x: hidden;
  }
</style>

