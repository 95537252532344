<template>
  <div class="mx-0">
    <v-card :class="`cardWidth mx-auto mt-2 mb-0 py-0 ${!isMobile ? 'heightDesktop' : (isMobile && mobileOperatingSystem == 'iOS') ? 'heightMobileIOS' : (isMobile && mobileOperatingSystem !== 'iOS') ? 'heightMobile' : ''}`">
      <v-divider></v-divider>
      <v-container
        class="contentMessagesIsReply"
        ref="messageContainer"
        v-if="cachedMessages"
      >
        <MessageHistoryEffector :messages="getHistoryMessages" />
      </v-container>
      <div class="px-6">
        <!-- <v-textarea
          :model-value="replyMessage" @update:model-value="replyMessage = $event"
          :no-resize="true"
          autofocus
          rows="2"
          variant="outlined"
          :label="$t('components.notificationsModal.reply')"
        ></v-textarea> -->
      </div>
      <div class="footerCard">
        <v-card-subtitle class="d-flex pt-1 pb-1 mb-0 px-2 text-right">
          <v-text-field
            :model-value="replyMessage" @update:model-value="replyMessage = $event"
            class="inputMessageWaitingRoom"
            @keyup.enter="replyMessage.trim().length > 0 ? sendNewMessage() : null"
            autofocus
            hide-details
            variant="outlined"
            single-line
            density="compact"
            :label="$t('components.notificationsModal.reply')"
          >

          <template slot="append">
             <v-btn
                class="ml-2"
                @click="sendNewMessage"
                icon
                :disabled="replyMessage.trim().length === 0"
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '20px' }"
                  color="grey"
                />
              </v-btn>
          </template>
          </v-text-field>

        </v-card-subtitle>
      </div>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import { isMobile, getMobileOperatingSystem } from "../../../lib/mobileUtil"
import MessageHistoryEffector from "../../content/messageHistoryEffector.vue";
import {
  // getMessageHistoryByUserUUID,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent,
  newMessageEvent,
  cachedMessages,
  messages,
} from "../../../effector/message";

export default {
  components: { MessageHistoryEffector },
  data() {
    const effector = {
      cachedMessages,
      messages,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      replyMessage: "",
      isMobile: isMobile(),
      mobileOperatingSystem: getMobileOperatingSystem(),
      // Effector
      ...effector,
    };
  },
  created() {},
  watch: {
    getHistoryMessages: {
      handler: function (messages) {
        this.updateMessageParams(messages);
      },
      deep: true,
    },
      totalMessages: {
      handler: function (total) {
        if (total) {
          const percentage =  (this.$refs["messageContainer"].scrollTop) / (this.$refs["messageContainer"].scrollHeight- this.$refs["messageContainer"].clientHeight ) * 100
          if (percentage > 70) {
              this.scrollToBottom();
          }
        }
      },
    },
  },
   mounted: function () {
       this.scrollToBottom()
   },
  methods: {
    scrollToBottom() {
      // scroll to bottom
      setTimeout(() => {
        this.$refs["messageContainer"].scrollTop = this.$refs[
          "messageContainer"
        ].scrollHeight;
      }, 300);
    },
    sendNewMessage() {
      const inviterUUID = this.state.user.visitorData.userInviter;
      const userToSendMsg = {};
      userToSendMsg[inviterUUID] = {
        userUUID: inviterUUID,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: this.replyMessage,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
      this.replyMessage = "";
    },
    updateMessageParams(data) {
      for (const messages in data) {
        if (data.hasOwnProperty(messages)) {
          const message = data[messages];
          if(message){
            if (message.users[this.state.ownUUID]) {
              if (!message.users[this.state.ownUUID].viewed && message.messageUUID) {
                markAsViewedSpecificMessageEvent(message.messageUUID);
              }
              if (!message.users[this.state.ownUUID].read && message.messageUUID) {
                markAsReadSpecificMessageEvent(message.messageUUID);
              }
            }
          }
        }
      }
    },
  },
  computed: {
    totalMessages() {
      return this.getHistoryMessages.length;
    },
    getHistoryMessages() {
      const ownUUID = store.state.ownUUID;
      const inviterUUID = this.state.user.visitorData.userInviter;
      const messagesHistory = this.messages.map((entry) => {
        if (
          (entry.creatorUUID === inviterUUID &&
            Object.keys(entry.users).find((user) => user == ownUUID)) ||
          (entry.creatorUUID === ownUUID &&
            Object.keys(entry.users).find((user) => user == inviterUUID))
        ) {
          return entry;
        }
      });
      // return messagesResult;
      return messagesHistory;
    },

  },
};
</script>

<style scoped lang="scss">
.heightDesktop{
  height: calc(100vh - 580px);
}
.heightMobileIOS{
  height: calc(100vh - 650px);
}
.heightMobile{
  height: calc(100vh - 600px);
}
.contentMessagesIsReply {
  height: calc(100% - 52px) !important;
  overflow: auto !important;
}
.cardWidth {
  max-width: 300px !important;
  box-shadow: none !important;
}
@media (max-width: 765px) {
  .custom6cols {
    width: 50% !important;
    min-width: 50% !important;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}

.footerCard {
  //background: #2a3133;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  //border: 2px solid #2a3133;
}
</style>
<style lang="scss">
.inputMessageWaitingRoom {
  fieldset{
    //color: white !important;
  }
  input, label{
    //color: white !important;
  }
  .v-input__append-inner{
    margin-top: 1px !important
  }
}
</style>
