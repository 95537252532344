<template>
  <div style="position: absolute; width: 100%" id="counter">
    <div
      class="backImage"
      :style="{ backgroundImage: 'url(' + getWorkersLoungeBg + ')' }"
    >
      <img
        id="over"
        :class="getClassTemplate"
        v-if="showLogoCompany"
        :src="showCompanyLogo"
        alt="1"
      />
    </div>
  </div>
</template>

<script>
import store from "../../../store";

export default {
  data() {
    return {
      state: store.state
    };
  },
  computed: {
    computedClassTitle() {
      // You can check against this.$vuetify.display names like xs, sm, md, lg, and xl.
      if (this.$vuetify.display.xs) {
        return "waitingTitleSmall  headline"; // Default class class for extra small screens
      } else if (this.$vuetify.display.sm) {
        return "waitingTitleSmall  headline"; // Default class class for small screens
      } else if (this.$vuetify.display.md) {
        return "waitingTitle  display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.display.lg) {
        return "waitingTitle  display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.display.xl) {
        return "waitingTitle  display-1"; // Default class for medium to larger screens
      }
      return "waitingTitle  display-1"; // Default if none of the above
    },
    computedClassText() {
      // You can check against this.$vuetify.display names like xs, sm, md, lg, and xl.
      if (this.$vuetify.display.xs) {
        return "waitingTextSmall  headline"; // Default class class for extra small screens
      } else if (this.$vuetify.display.sm) {
        return "waitingTextSmall  headline"; // Default class class for small screens
      } else if (this.$vuetify.display.md) {
        return "waitingText  display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.display.lg) {
        return "waitingText  display-1"; // Default class for medium to larger screens
      } else if (this.$vuetify.display.xl) {
        return "waitingText  display-1"; // Default class for medium to larger screens
      }
      return "waitingText  display-1"; // Default if none of the above
    },
    showText() {
      const workersLoungeBg = this.getWorkersLoungeBg;
      if (workersLoungeBg && workersLoungeBg === 'img/templates/wr_back_02.png') return true;
      return false;
    },
    getWorkersLoungeBg() {
      if (store.state.namespaceSettings.workersLoungeBackground) {
        return (
          store.state.namespaceSettings.workersLoungeBackground ||
          "img/templates/wr_back_01.png"
        );
      } else {
        return (
          store.state.namespaceSettings.workersLoungeSocialMedia.templateBg
            .imagePath || "img/templates/wr_back_01.png"
        );
      }
    },
    showLogoCompany() {
      if (
        !store.state.namespaceSettings.workersLoungeBackground &&
        store.state.namespaceSettings.workersLoungeSocialMedia.templateBg
      ) {
        return true;
      }
      return false;
    },
    getClassTemplate() {
      if (this.showLogoCompany) {
        return (
          store.state.namespaceSettings.workersLoungeSocialMedia.templateBg
            .imageClass || "imageClass1"
        );
      } else {
        return "";
      }
    },
    showCompanyLogo() {
      return this.state.namespaceSettings.companyLogo || "img/icon.png";
    },
  },
  methods: {
    // Replace newline characters with <br> tags
    formatWelcomeMsgMultiline(msg) {
      return msg.replace(/\n/g, '<br>');
    },
  }
};
</script>

<style scoped lang="scss">
.waitingTitleBackground {
  background: rgb(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 22px;
}
.waitingTitleSmall{
  position: relative; /* or absolute for out-of-flow positioning */
  left: 45%;
  top: 15%;
  transform: translate(-48%, 0);
  width: 110%; /* or any specific width */
  margin-left: auto;
  margin-right: auto;
}
.waitingTitle{
  position: relative; /* or absolute for out-of-flow positioning */
  left: 45%;
  top: 15%;
  transform: translate(-48%, 0);
  width: 110%; /* or any specific width */
  margin-left: auto;
  margin-right: auto;
}
.waitingText{
     left: 29%;
    top: 33%;
    position: relative;
    width: 23%;
}
.waitingTextSmall{
     left: 29%;
    top: 30%;
    position: relative;
    width: 23%;
}
.backImage {
  height: calc(100vh - 123px);
  min-width: calc(100vw - 58px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.backImage {
  height: calc(100vh - 50px);
  width: 100vw;
}
#counter {
  position: relative;
  display: inline;
}
#over {
  position: absolute;
  width: 8%;
  z-index: 0;
  background-color: #e4e4e4;
  border-radius: 50%;
  padding: 1%;
  border: 3px solid #dadada;
  box-shadow: 0 5px 15px 0 #3f3f40;
}
.imageClass1 {
  left: 52%;
  top: 28%;
}
.imageClass2{
left: 53%;
    top: 29%;
}
.imageClass3{
left: 71%;
    top: 12%;
}
.imageClass4{
  left: 50%;
  top: 34%;
}
.imageClass5{
  left: 51%;
  top: 16%;

}
.imageClass6{
  left: 43%;
  top: 37%;
}
.imageClass7{
  left: 56%;
  top: 27%;
}
.imageClass8{
  left: 54%;
  top: 36%;
}
.imageClass9{
  left: 63%;
  top: 28%;
}
.imageClass10{
  left: 38%;
  top: 28%;
}
</style>